import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import AddForm from "./AddForm";
import EditForm from "./EditForm";
import Skeleton from "@mui/material/Skeleton";
import { useAppStore } from "../appStore";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function UsersList() {
    const baseURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [formid, setFormid] = useState("");
    const [open, setOpen] = useState(false);
    const [editopen, setEditOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleEditOpen = () => setEditOpen(true);
    const handleClose = () => setOpen(false);
    const handleEditClose = () => setEditOpen(false);
    const notifications = useAppStore((state) => state.notifications);
    const updateNotifications = useAppStore((state) => state.updateNotifications);
    const token = useAppStore((state) => state.token);
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate("/");
        }
        getUsers();
    }, []);

    const getUsers = async () => {
        let posturl = baseURL + "notify";
        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                updateNotifications(res.data);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                deleteApi(id);
            }
        });
    };

    const deleteApi = async (id) => {
        let posturl = baseURL + "notify/" + id;
        await axios
            .delete(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (res) {
                console.log(res.data);
                getUsers();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const filterData = (v) => {
        if (v) {
            // setRows([v]);
            updateNotifications([v]);
        } else {
            getUsers();
        }
    };

    const editData = (id, notification_title, notification_text, status) => {
        const data = {
            id: id,
            notification_title: notification_title,
            notification_text: notification_text,
            status: status,
        };
        setFormid(data);
        handleEditOpen();
    };

    return (
        <>
            <div>
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <AddForm closeEvent={handleClose} />
                    </Box>
                </Modal>
                <Modal
                    open={editopen}
                    // onClose={handleEditClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <EditForm closeEvent={handleEditClose} fid={formid} />
                    </Box>
                </Modal>
            </div>

            <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ padding: "20px" }}
                >
                    Notification
                </Typography>
                <Divider />
                <Box height={10} />
                <Stack direction="row" spacing={2} className="my-2 mb-2">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={notifications}
                        sx={{ width: 300 }}
                        onChange={(e, v) => filterData(v)}
                        getOptionLabel={(notifications) => notifications.notification_title || ""}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                label="Search..."
                            />
                        )}
                    />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    ></Typography>
                    <Button
                        variant="contained"
                        endIcon={<AddCircleIcon />}
                        onClick={handleOpen}
                    >
                        Add
                    </Button>
                </Stack>
                <Box height={10} />
                {notifications.length > 0 && (
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        ID
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Title
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Content
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Updated At
                                    </TableCell>
                                    <TableCell
                                        align="left"
                                        style={{ minWidth: "100px", fontWeight: "bold" }}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notifications
                                    .slice(
                                        page * rowsPerPage,
                                        page * rowsPerPage + rowsPerPage
                                    )
                                    .map((row) => {
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.code}
                                            >
                                                <TableCell align="left">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.notification_title}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.notification_text}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {row.status === "Active" ? (
                                                        <span className="text-green-600">
                                                            {row.status}
                                                        </span>
                                                    ) : (
                                                        <span className="text-orange-600">
                                                            {row.status}
                                                        </span>
                                                    )}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {moment(
                                                        row.modified_at
                                                    ).format("MMMM Do YYYY")}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Stack
                                                        spacing={2}
                                                        direction="row"
                                                    >
                                                        <EditIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "blue",
                                                                cursor: "pointer",
                                                            }}
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                editData(
                                                                    row.id,
                                                                    row.notification_title,
                                                                    row.notification_text,
                                                                    row.status,
                                                                );
                                                            }}
                                                        />
                                                        <DeleteIcon
                                                            style={{
                                                                fontSize:
                                                                    "20px",
                                                                color: "darkred",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                deleteUser(
                                                                    row.id
                                                                );
                                                            }}
                                                        />
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={notifications.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>

            {notifications.length === 0 && (
                <>
                    <Paper
                        sx={{
                            width: "98%",
                            overflow: "hidden",
                            padding: "12px",
                        }}
                    >
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={30}
                        />
                        <Box height={40} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={60}
                        />
                        <Box height={20} />
                    </Paper>
                </>
            )}
        </>
    );
}
