import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from "@mui/material/Modal";
import AddForm from "./AddForm";
import EditForm from "./EditForm";
import { useAppStore } from "../appStore";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export default function UsersList() {
    const baseURL = process.env.REACT_APP_API_URL;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [formid, setFormid] = useState("");
    const [open, setOpen] = useState(false);
    const [editopen, setEditOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleEditOpen = () => setEditOpen(true);
    const handleClose = () => setOpen(false);
    const handleEditClose = () => setEditOpen(false);
    const users = useAppStore((state) => state.users);
    const updateUsers = useAppStore((state) => state.updateUsers);
    const adminEmail = useAppStore((state) => state.adminEmail);
    const token = useAppStore((state) => state.token);
    const navigate = useNavigate();
    const [isLoaded, setIsLoaded] = useState(false);
    const utype = useAppStore((state) => state.utype);
    const [searchQuery, setSearchQuery] = useState("");
    const [emprecord, SetEmprecord] = useState([]);
    const tenHoursAgo = moment().subtract(10, 'hours');

    useEffect(() => {
        searchData(searchQuery);
    }, [searchQuery])

    const searchData = (searchQuery) => {
        let filteredData = users;
        if (searchQuery) {
            filteredData = users.filter(usr =>
                usr?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.koid?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.type?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.phone?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                usr?.email?.toLowerCase().includes(searchQuery.toLowerCase())
            )
            console.log('searchQuery ', filteredData);
            SetEmprecord(filteredData);
        } else {
            SetEmprecord(users);
        }

    }

    useEffect(() => {
        // console.log('Users ');
        if (!token) {
            navigate("/");
        }
        getUsersCall();
    }, []);

    const getUsersCall = () => {
        if (utype === "Admin") {
            let posturl = baseURL + "users";
            getUsers(posturl);
        }
        if (utype === "Project Head") {
            let posturl = baseURL + "users";
            getUsers(posturl);
        }
        if (utype === "Area Coordinator") {
            let posturl = baseURL + "onlyuser";
            getUsers(posturl);
        }
        if (utype === "District Coordinator") {
            let posturl = baseURL + "onlyuser";
            getUsers(posturl);
        }
    }

    const getUsers = async (posturl) => {

        await axios
            .get(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => {
                // console.log('Users ', res.data);
                updateUsers(res.data);
                SetEmprecord(res.data);
                setIsLoaded(true);
            })
            .catch((err) => {
                console.log("kcheckpost" + err); //return 429
            });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const deleteUser = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                deleteApi(id);
            }
        });
    };

    const deleteApi = async (id) => {
        let posturl = baseURL + "users/" + id;
        await axios
            .delete(posturl, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(function (res) {
                // console.log(res.data);
                getUsersCall();
                Swal.fire(
                    "Submitted!",
                    "Your file has been submitted.",
                    "success"
                );
            })
            .catch(function (error) {
                console.log("kcheckpost" + error); //return 429
            });
    };

    const filterData = (v) => {
        if (v) {
            // setRows([v]);
            updateUsers([v]);
        } else {
            getUsersCall();
        }
    };


    const editData = (id,
        name,
        email,
        password,
        type,
        status,
        reason,
        pincode,
        koid,
        availability,
        state,
        district,
        address,
        loanwiser,
        phone,
        loanwiserpincode) => {
        const data = {
            id: id,
            name: name,
            email: email,
            password: password,
            type: type,
            status: status,
            reason: reason,
            pincode: pincode,
            koid: koid,
            availability: availability,
            state: state,
            district: district,
            address: address,
            loanwiser: loanwiser,
            phone: phone,
            loanwiserpincode: loanwiserpincode,
        };
        setFormid(data);
        handleEditOpen();
    };

    return (
        <>
            <div>
                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <AddForm closeEvent={handleClose} />
                    </Box>
                </Modal>
                <Modal
                    open={editopen}
                    // onClose={handleEditClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <EditForm closeEvent={handleEditClose} fid={formid} />
                    </Box>
                </Modal>
            </div>
            {isLoaded && (<>
                <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ padding: "20px" }}
                    >
                        Users
                    </Typography>
                    <Divider />
                    <Box height={10} />
                    <Stack direction="row" spacing={2} className="my-2 mb-2">
                        {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={users}
                            sx={{ width: 300 }}
                            onChange={(e, v) => filterData(v)}
                            getOptionLabel={(users) => users.koid || ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    size="small"
                                    label="Search User"
                                />
                            )}
                        /> */}
                        <div>
                            <input
                                type="text"
                                placeholder="Search Users"
                                value={searchQuery}
                                onChange={(e) => (setSearchQuery(e.target.value))}
                                className="rounded-full py-2 px-4 border border-solid border-gray-400"
                            />
                        </div>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ flexGrow: 1 }}
                        ></Typography>
                        <Button
                            variant="contained"
                            endIcon={<AddCircleIcon />}
                            onClick={handleOpen}
                        >
                            Add
                        </Button>
                    </Stack>
                    <Box height={10} />
                    {users.length > 0 && (
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Email
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            KOID
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Type
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Availability
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Last Seen
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{ minWidth: "100px", fontWeight: "bold" }}
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {emprecord
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((row) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={row.code}
                                                >
                                                    <TableCell align="left">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.email}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.koid}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {row.type}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {moment(row?.login_at).isAfter(tenHoursAgo) ? (
                                                            <span className="text-green-600">
                                                                Online
                                                            </span>
                                                        ) : (
                                                            <span className="text-orange-600">
                                                                Offline
                                                            </span>
                                                        )}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {moment(
                                                            row.login_at
                                                        ).format("MMMM Do YYYY, h:mm:ss a")}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        <Stack
                                                            spacing={2}
                                                            direction="row"
                                                        >
                                                            <EditIcon
                                                                style={{
                                                                    fontSize:
                                                                        "20px",
                                                                    color: "blue",
                                                                    cursor: "pointer",
                                                                }}
                                                                className="cursor-pointer"
                                                                onClick={() => {
                                                                    editData(
                                                                        row.id,
                                                                        row.name,
                                                                        row.email,
                                                                        row.password,
                                                                        row.type,
                                                                        row.status,
                                                                        row.reason,
                                                                        row.pincode,
                                                                        row.koid,
                                                                        row.availability,
                                                                        row.state,
                                                                        row.district,
                                                                        row.address,
                                                                        row.loanwiser,
                                                                        row.phone,
                                                                        row.loanwiserpincode,
                                                                    );
                                                                }}
                                                            />
                                                            {adminEmail === "manu@cdotbihar.org" && (
                                                                <DeleteIcon
                                                                    style={{
                                                                        fontSize:
                                                                            "20px",
                                                                        color: "darkred",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                        deleteUser(
                                                                            row.id
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                    <TablePagination
                        rowsPerPageOptions={[100]}
                        component="div"
                        count={emprecord.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    // onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </>)}

        </>
    );
}
