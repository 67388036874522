import React, { useEffect } from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAppStore } from "../appStore";
import axios from "axios";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const schema = yup
    .object({
        name_of_bank: yup.string().required(),
        branch_code: yup.string().required(),
        customer_account: yup.string().required(),
        customer_name: yup.string().required(),
        form_submission_date: yup.string().required(),
        scheme: yup.string().required(),
    })
    .required();

export default function AddSocialData() {
    const baseURL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const token = useAppStore((state) => state.token);
    const location = useLocation();
    const utype = useAppStore((state) => state.utype);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    useEffect(() => {
        console.log("FID: ", location.state);
        reset(location.state)
        // setName(location.state.name);

    }, []);

    const onSubmit = async (data) => {
        createUser(data);
    };

    const createUser = async (data) => {
        let posturl = baseURL + "social";
        const tid = toast.loading("Please wait...");

        await axios
            .post(
                posturl,
                data,
                {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-type": "Application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(function (res) {
                toast.update(tid, {
                    render: "Created!",
                    type: "success",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                });
                navigate("/social-security-report");
            })
            .catch(function (error) {
                toast.update(tid, {
                    render: error,
                    type: "error",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    isLoading: false,
                    theme: "light",
                });
                console.log("kcheckpost" + error); //return 429
            });
    };


    return (
        <>
            <ToastContainer />
            <div className="bgcolor">
                <Navbar />
                <Box height={70} />
                <Box sx={{ display: "flex" }}>
                    <Sidenav />
                    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Card
                            sx={{
                                width: "98%",
                                overflow: "hidden",
                                padding: "12px",
                            }}
                        >
                            <Typography variant="h5" align="center">
                                Edit Social Security Scheme Report
                            </Typography>
                            <Box height={10} />
                            <IconButton
                                style={{
                                    position: "absolute",
                                    top: "-10",
                                    left: "20",
                                }}
                                onClick={() => {
                                    navigate("/social-security-report");
                                }}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                            <Box height={20} />
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl
                                            variant="outlined"
                                            sx={{ minWidth: "100%", marginTop: "30px", }}
                                            size="small"
                                        >
                                            <InputLabel id="branch-label">Scheme</InputLabel>
                                            <Select
                                                size="small"
                                                {...register("scheme")}
                                                name="scheme"
                                                label="Scheme"
                                            >
                                                <MenuItem value="">Select Scheme</MenuItem>
                                                <MenuItem value="Pradhan Mantri Suraksha Bima Yojana (PMSBY)">Pradhan Mantri Suraksha Bima Yojana (PMSBY)</MenuItem>
                                                <MenuItem value="Pradhan Mantri Suraksha Bima Yojana (PMSBY)">Pradhan Mantri Suraksha Bima Yojana (PMSBY)</MenuItem>
                                                <MenuItem value="Atal Pension Yojana (APY)">Atal Pension Yojana (APY)</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <p className="text-sm text-orange-600">{errors.scheme?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Name of Bank"
                                            {...register("name_of_bank")}
                                            name="name_of_bank"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                                marginTop: "30px",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.name_of_bank?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("branch_code")}
                                            name="branch_code"
                                            label="Branch Code"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.branch_code?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("branch_name")}
                                            name="branch_name"
                                            label="Branch Name"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.branch_name?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("customer_account")}
                                            name="customer_account"
                                            label="Customer Account"
                                            type="number"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.customer_account?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("customer_name")}
                                            name="customer_name"
                                            label="Customer Name"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                        <p className="text-sm text-orange-600">{errors.customer_name?.message}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("apy")}
                                            name="apy"
                                            label="APY Done"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("pmsby")}
                                            name="pmsby"
                                            label="PMSBY Done"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            {...register("pmjjby")}
                                            name="pmjjby"
                                            label="PMJJBY Done"
                                            type="text"
                                            size="small"
                                            sx={{
                                                minWidth: "100%",
                                            }}
                                        />
                                    </Grid>
                                    {utype == "BC Agent" && (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant="h5" align="center">
                                                    <Button
                                                        variant="contained"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </form>
                        </Card>
                    </Box>
                </Box>
            </div>
        </>
    );
}
