import React, { useEffect, useState } from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useAppStore } from "../appStore";
import axios from "axios";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function Profile() {
  const baseURL = process.env.REACT_APP_API_URL;
  const token = useAppStore((state) => state.token);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [type, setType] = useState("User");

  const [status, setStatus] = useState("Active");
  const [phone, setPhone] = useState("");
  const [reason, setReason] = useState("");
  const [pincode, setPincode] = useState("");
  const [koid, setKoid] = useState("");
  const [availability, setAvailability] = useState("Online");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [address, setAddress] = useState("");
  const [loanwiser, setLoanwiser] = useState("");
  const [loanwiserpincode, setLoanwiserPincode] = useState("");

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    let posturl = baseURL + "profile";

    await axios
      .get(
        posturl,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        console.log(res.data);
        setName(res.data.name);
        setEmail(res.data.email);
        setType(res.data.type);
        setStatus(res.data.status);
        setPhone(res.data.phone);
        setPincode(res.data.pincode);
        setState(res.data.state);
        setDistrict(res.data.district);
        setAddress(res.data.address);
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
  };


  const createUser = async (url) => {
    let posturl = baseURL + "bca";
    const tid = toast.loading("Please wait...");

    await axios
      .post(
        posturl,
        {
          name: name,
          email: email,
          password: password,
          type: type,
          password_confirmation: confirmpassword,
          phone: phone,
          pincode: pincode,
          koid: koid,
          state: state,
          district: district,
          address: address,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        toast.update(tid, {
          render: "Created!",
          type: "success",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          isLoading: false,
        });
      })
      .catch(function (error) {
        toast.update(tid, {
          render: error,
          type: "error",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          isLoading: false,
          theme: "light",
        });
        console.log("kcheckpost" + error); //return 429
      });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handlePincodeChange = (event) => {
    setPincode(event.target.value);
  };

  const handleKoidChange = (event) => {
    setKoid(event.target.value);
  };

  const handleAvailabilityChange = (event) => {
    setAvailability(event.target.value);
  };

  const handleStateChange = (event) => {
    setState(event.target.value);
  };

  const handleDistrictChange = (event) => {
    setDistrict(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleLoanwiserChange = (event) => {
    setLoanwiser(event.target.value);
  };

  const handleLoanwiserPincodeChange = (event) => {
    setLoanwiserPincode(event.target.value);
  };


  return (
    <>
      <ToastContainer />
      <div className="bgcolor">
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Card
              sx={{
                width: "98%",
                overflow: "hidden",
                padding: "12px",
              }}
            >
              <Typography variant="h5" align="center">
                My Profile
              </Typography>
              <Box height={10} />
              <Box height={20} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    error={false}
                    value={name}
                    onChange={handleNameChange}
                    label="Name"
                    size="small"
                    sx={{ marginTop: "30px", minWidth: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={false}
                    value={email}
                    onChange={handleEmailChange}
                    label="Email"
                    size="small"
                    sx={{ marginTop: "30px", minWidth: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={false}
                    value={password}
                    onChange={handlePasswordChange}
                    label="Password"
                    type="password"
                    size="small"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    error={false}
                    value={confirmpassword}
                    onChange={handleConfirmPasswordChange}
                    label="Confirm Password"
                    size="small"
                    type="password"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="branch-label">Type*</InputLabel>
                    <Select
                      labelId="branch-label"
                      size="small"
                      value={type}
                      label="Type*"
                      onChange={handleTypeChange}
                    >
                      <MenuItem value="User">User</MenuItem>
                      <MenuItem value="Admin">Admin</MenuItem>
                      <MenuItem value="Project Head">Project Head</MenuItem>
                      <MenuItem value="Area Coordinator">Area Coordinator</MenuItem>
                      <MenuItem value="District Coordinator">District Coordinator</MenuItem>
                      <MenuItem value="BC Agent">BC Agent</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                {/* <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="branch-label">Status</InputLabel>
                    <Select
                      size="small"
                      value={status}
                      label="Status"
                      onChange={handleStatusChange}
                    >
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Inactive">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    value={phone}
                    onChange={handlePhoneChange}
                    label="Phone"
                    size="small"
                    type="text"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <TextField
                    value={reason}
                    onChange={handleReasonChange}
                    label="Reason"
                    size="small"
                    type="text"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    value={pincode}
                    onChange={handlePincodeChange}
                    label="Pincode"
                    size="small"
                    type="text"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={koid}
                    onChange={handleKoidChange}
                    label="KOID"
                    size="small"
                    type="text"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="branch-label">Availability</InputLabel>
                    <Select
                      size="small"
                      value={availability}
                      onChange={handleAvailabilityChange}
                      label="Availability"
                    >
                      <MenuItem value="Online">Online</MenuItem>
                      <MenuItem value="Offline">Offline</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item xs={6}>
                  <TextField
                    value={state}
                    onChange={handleStateChange}
                    label="State"
                    size="small"
                    type="text"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={district}
                    onChange={handleDistrictChange}
                    label="District"
                    size="small"
                    type="text"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={address}
                    onChange={handleAddressChange}
                    label="Address"
                    size="small"
                    type="text"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid>
                {/* <Grid item xs={6}>
                  <TextField
                    value={loanwiser}
                    onChange={handleLoanwiserChange}
                    label="Loanwiser"
                    size="small"
                    type="text"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid> */}
                {/* <Grid item xs={6}>
                  <TextField
                    value={loanwiserpincode}
                    onChange={handleLoanwiserPincodeChange}
                    label="Loanwiser Pincode"
                    size="small"
                    type="text"
                    sx={{ minWidth: "100%" }}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Typography variant="h5" align="center">
                    <Button variant="contained" onClick={() => createUser()}>
                      Update
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Box>
      </div>
    </>
  );
}
