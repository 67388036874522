import React, { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useAppStore } from "../appStore";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Marquee from "react-fast-marquee";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

export default function Navbar() {
  const updateDopen = useAppStore((state) => state.updateDopen);
  const dopen = useAppStore((state) => state.dopen);
  const updateChatUsers = useAppStore((state) => state.updateChatUsers);
  const updateIsLoanwiser = useAppStore((state) => state.updateIsLoanwiser);
  const updateAdminEmail = useAppStore((state) => state.updateAdminEmail);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("Active");
  const baseURL = process.env.REACT_APP_API_URL;
  const token = useAppStore((state) => state.token);
  const [notification, setNotification] = useState("");
  const updateUsername = useAppStore((state) => state.updateUsername);
  const updatePassword = useAppStore((state) => state.updatePassword);
  const updateToken = useAppStore((state) => state.updateToken);

  useEffect(() => {
    getProfile();
    getUsers();
    getNotification();
  }, []);

  const getNotification = async () => {
    let posturl = baseURL + "notify";

    await axios
      .get(
        posturl,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        setNotification(res.data[0].notification_text);
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
        if (error.response.status === 401) {
          logmeout();
        }
      });
  };

  const logmeout = async () => {
    updateToken("");
    updateUsername("");
    updatePassword("");
    let posturl = baseURL + "logout";
    await axios
      .post(posturl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (res) {
        console.log(res.data);
        navigate("/");
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
    navigate("/");
  };

  const getUsers = async () => {
    let posturl = baseURL + "users";

    await axios
      .get(
        posturl,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        // console.log('loanwiser ', res.data);
        updateChatUsers(res.data);
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
        if (error.response.status === 401) {
          logmeout();
        }
      });
  };

  const getProfile = async () => {
    let posturl = baseURL + "profile";

    await axios
      .get(
        posturl,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        // console.log(res.data);
        setName(res.data.name);
        setEmail(res.data.email);
        updateAdminEmail(res.data.email);
        setType(res.data.type);
        setStatus(res.data.status);
        setPhone(res.data.phone);
        updateIsLoanwiser(res.data.loanwiser);
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
  };


  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "#ffffff", color: "#2f2f2f" }}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={() => {
              updateDopen(!dopen);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
            style={{ minWidth: "200px" }}
          >
            CDOT BMS
          </Typography>
          <Marquee className="px-4 mr-7 flex">
            <span>
              <ArrowCircleRightIcon className="text-orange-700 mr-1 mb-1" />
            </span>
            <span className="font-bold mr-10">
              {notification}
            </span>
          </Marquee>
          <Box sx={{ flexGrow: 1 }} />
          <Typography
            variant="p"
            noWrap
            component="div"
            style={{ minWidth: "200px" }}
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            {name}, ({type})<br />
            {email}<br />
          </Typography>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
