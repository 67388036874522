import { useEffect, useState } from "react";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export default function Received() {
  const baseURL = process.env.REACT_APP_API_URL;
  const token = useAppStore((state) => state.token);
  const [messages, setMessages] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (!token) {
      navigate("/");
    }

    getUsers();
  }, []);

  const getUsers = async () => {
    let posturl = baseURL + "onlychat";
    await axios
      .get(posturl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log('Messages ', res.data);
        setMessages(res.data);
      })
      .catch((err) => {
        console.log("kcheckpost" + err); //return 429
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div className="flex justify-center">
      <div>
        <h1 className="text-lg text-bold text-center">Received Messages</h1>
        <div className="mt-3">
          {messages.length > 0 && (
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow style={{}}>
                    <TableCell
                      align="center"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      From
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Message
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Rec. On
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messages
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="left">
                            {row.from}
                          </TableCell>
                          <TableCell align="left">
                            {row.message}
                          </TableCell>
                          <TableCell align="left">
                            {moment(
                              row.created_at
                            ).format("MMMM Do YYYY")}
                          </TableCell>

                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={messages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  )
}
