import { useState } from "react";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

export default function Sent({ messages }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <div className="flex justify-center">
      <div>
        <h1 className="text-lg text-bold text-center">Sent Messages</h1>
        <div className="mt-3">
          {messages.length > 0 && (
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow style={{}}>
                    <TableCell
                      align="center"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      To
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Message
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "100px", fontWeight: "bold" }}
                    >
                      Sent On
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {messages
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell align="left">
                            {row.email}
                          </TableCell>
                          <TableCell align="left">
                            {row.message}
                          </TableCell>
                          <TableCell align="left">
                            {moment(
                              row.created_at
                            ).format("MMMM Do YYYY")}
                          </TableCell>

                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={messages.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </div>
  )
}
